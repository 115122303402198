import React, {useState, useRef, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Col, Tab, Nav, Card, Button, Pagination, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import PageTitle from '../../layouts/PageTitle';

import axios from 'axios';

import config from '../../../config';
import { set } from 'rsuite/esm/utils/dateUtils';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: '이름', sortingValue:""},
    {heading: '이메일', sortingValue:""},
    /*
    {heading: '강의유형', sortingValue:""},
    {heading: '회원유형', sortingValue:""},
    {heading: '소속', sortingValue:""},
    */
    {heading: '시작시간', sortingValue:""},
    {heading: '종료시간', sortingValue:""},
    {heading: '총 수업시간', sortingValue:""},
    /*
    {heading: '강의상태', sortingValue:""},
    */
    {heading: '출결', sortingValue:""},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const LectureData = () => {

    useTitle("강의현황 자료");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기  

    // 세부 기록 테이블
    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(10);
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);

    // 클라우드 테이블
    const [cloudCnt, setCloudCnt] = useState(0);
    const [cloudactivepage, setcloudactivepage] = useState(1);
    const [cloudRow, setCloudRow] = useState(1);
    const cloudTotalPages = Math.ceil(cloudCnt / cloudRow); // 총 페이지 수
    const [cloudData, setCloudData] = useState([]);
    const [pageItems, setPageItems] = useState([]);

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        getRecordings();
        getAttendance();
    }, []);

    // 클라우드 리스트 가져오기
    const getRecordings = async () => {
        
        try {
            const response = await axios.get(apiUrl+'/zoom/meeting/recordings/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                if(res.status == 200){
                    var resData = res.data.recordings;
                    var scheduleData = res.data.schedule;
                    //console.log(resData);
                    if(resData){
                        if(resData.recording_files){
                            for(var i = 0; i < resData.recording_files.length; i++){
                                var info = resData.recording_files[i];
                                info.datetxt = scheduleData.datetxt;
                                info.weektxt = scheduleData.weektxt;
                                info.starttxt = scheduleData.starttxt;
                                info.tutorname = scheduleData.name;
                                info.password = resData.password;
                                info.title = resData.topic;
                                info.num = i + 1;
                                
                                dataArr.push(info);
                            } 
                        }
                    }

                    setCloudCnt(resData.recording_files.length);
                }else{
                    setCloudCnt(0);
                }
            }else{
                setCloudCnt(0);
            }
            
            setCloudData(dataArr);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    const pag = (size, gutter, variant, bg, circle) => (
        <Pagination
            size={size}
            className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${
                variant && `pagination-${variant}`
            } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
        >
            <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={prevCloudPage}>
                <i className="la la-angle-left" />
                </Link>
            </li>
            {renderCloudPaginationButtons()}
            <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={nextCloudPage}>
                <i className="la la-angle-right" />
                </Link>
            </li>
        </Pagination>
    );

    // 페이징 버튼 렌더링
    const renderCloudPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = cloudactivepage; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(cloudTotalPages, startPage + maxButtons - 1);

        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Pagination.Item
                    key={i}
                    to='#'
                    active={i === currentPage}
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onCloudPageClick(i- 1)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return paginationButtons;
    };

    // 페이지 변경 시
    const onCloudPageClick = (i) => {
        setcloudactivepage(i + 1);
    }

    // 이전 페이지
    const prevCloudPage = () => {
        if (cloudactivepage > 1) {
            setcloudactivepage(cloudactivepage - 1);
        }
    };

    // 다음 페이지
    const nextCloudPage = () => {
        if (cloudactivepage < cloudTotalPages) {
            setcloudactivepage(cloudactivepage + 1);
        }
    };
    
    // 세부 기록 (출석현황) 리스트 가져오기
    const getAttendance = async () => {
        try {
            const response = await axios.get(apiUrl+'/zoom/meeting/attendance/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            // console.log(res);
            var dataArr = [];
            if(res.success){
                if(res.data.attendance){
                    var resData = res.data.attendance.participants;
                    // console.log(resData);
                    if(resData != null && resData != undefined && resData != "undefined"){
                        if(resData.length > 0){
                            for(var i = 0; i < resData.length; i++){
                                var info = resData[i];
                                var starttime = new Date(info.join_time);
                                var endtime = new Date(info.leave_time);
                                info.starttime = formatDateTime(starttime);
                                info.endtime = formatDateTime(endtime);
                                info.duration_toString = secToTime(info.duration);
                                
                                dataArr.push(info);
                            }
                        }

                        setTotalCnt(resData.length);
                    }else{
                        setTotalCnt(0);
                    }
                }

            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 날짜 포맷 변경
    const formatDateTime = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = String(d.getDate()).padStart(2, '0');
        const hour = String(d.getHours()).padStart(2, '0');
        const minute = String(d.getMinutes()).padStart(2, '0');
        const second = String(d.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    // sec => 시분초로 변경
    const secToTime = (sec) => {
        var hours = Math.floor(sec / 3600);
        var minutes = Math.floor((sec - (hours * 3600)) / 60);
        var seconds = sec - (hours * 3600) - (minutes * 60);
        var timeStr = hours + "시간 " + minutes + "분 " + seconds + "초";
        if(hours == 0){
            timeStr = minutes + "분 " + seconds + "초";
        }
        if(hours == 0 && minutes == 0){
            timeStr = seconds + "초";
        }
        return timeStr;
    }

    // 클라우드 다운로드
    const downloadRecording = async (url) => {
        window.open(url);
    };
    
    // 엑셀 다운로드
    const downloadExcel = async () => {
        try {
            const response = await axios.get(apiUrl+'/zoom/meeting/attendanceexcel/'+id, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '출결 자료.xlsx');
        } catch (error) {
           console.error('Error:', error);
           alert("다운로드할 항목이 존재하지 않습니다.");
        }
    }
      
    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getAttendance();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getAttendance();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getAttendance();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };
      
    const [iconData, setIconDate] = useState({ complete: false ,ind : Number});
    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getAttendance();
        }
    }, [sort]);
    
    // 공유 모달창
    const [modalCentered, setModalCentered] = useState(false);
    const [copyUrl, setCopyUrl] = useState('');
    const [copyPassword, setCopyPassword] = useState('');

    // 공유 모달창 열기
    const openCopyModal = (url, password) => {
        setCopyUrl(url);
        setCopyPassword(password);
        setModalCentered(true);
    };

    // 링크 & 암호 복사
    const copyToClipboard = () => {
        var copyText = "URL : " + copyUrl + "\n" + "암호 : " + copyPassword;
        navigator.clipboard.writeText(copyText)
        .then(() => {
            alert('URL과 암호 복사되었습니다.');
            setModalCentered(false)
        })
        .catch((error) => {
            console.error('복사에 실패했습니다:', error);
        });
    };

    // 클라우드 녹화본 삭제
    const deleteRecording = async (meetingid) => {
        var result = window.confirm("녹화본을 삭제하시겠습니까?");
        if(result){
            try {
                const response = await axios.delete(apiUrl+'/zoom/meeting/recordings/'+meetingid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    if(res.status == 200){
                        alert("삭제되었습니다.");
                        getRecordings();
                    }else{
                        alert("삭제에 실패했습니다.");
                    }
                }else{
                    alert("삭제에 실패했습니다.");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    
    return (
        <>
            <PageTitle motherMenu={"수업관리"} grandMenu={"강의현황"} grandMenuLink="/AllLecture" activeMenu={"자료"}  />
            
            <Row>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-tab">
                                <Tab.Container defaultActiveKey='cloud'>
                                    <Nav as='ul' className="nav nav-tabs">
                                        <Nav.Item as='li' className="nav-item">
                                            <Nav.Link href="#my-cloud" eventKey='cloud'>클라우드 기록</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as='li' className="nav-item">
                                            <Nav.Link href="#about-detail" eventKey='detail'>세부 기록</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane id="my-cloud" eventKey='cloud' className='my-cloud'>
                                            <Row className='p-4'>
                                                {cloudData.slice(cloudactivepage - 1, cloudactivepage - 1 + cloudRow).map((data, ind)=>(
                                                    <Col key={ind} xl={3} xxl={4} lg={4} md={6} sm={6} >
                                                        <Card>
                                                            <div className="vodBox">
                                                            </div>
                                                            <div className="card-body">
                                                                <h4 className='d-flex align-items-center gap-2'>
                                                                <strong >({data.num}) {data.title}</strong></h4>
                                                                <ul className="list-group mb-3 list-group-flush">
                                                                    <li
                                                                        className="list-group-item px-0 date">
                                                                        <span className="mb-0">{data.datetxt} ({data.weektxt}) {data.starttxt} {data.tutorname} 강사님</span>
                                                                    </li>
                                                                </ul>
                                                    
                                                                <div className="d-flex flex-wrap gap-2 align-content-end">
                                                                    <Button to={"/about-courses"} className=" btn btn-primary light" onClick={() => downloadRecording(data.download_url)}>다운로드</Button>
                                                                    <Button to={"/about-courses"} className="btn  btn-primary light " onClick={() => openCopyModal(data.play_url, data.password)}>공유</Button>
                                                                    <Button to={"/about-courses"} className="btn btn-danger light" onClick={() => deleteRecording(data.meeting_id)}>삭제</Button>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                                
                                                {/* 페이징 */}
                                                <div className='paging'>
                                                    {pag("", false, "", true, false)}
                                                </div>
                                            </Row>                                       
                                        </Tab.Pane>
                                            
                                        <Tab.Pane id="about-detail" eventKey='detail'>
                                            <div className="mt-4 mb-4">
                                                <button onClick={downloadExcel} className="btn btn-primary btn-sm">EXCEL 다운로드</button>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <div id='holidayList' className='dataTables_wrapper no-footer'>
                                                            <div className='justify-content-between d-sm-flex'>
                                                                <div className='dataTables_length'>
                                                                    <label className='d-flex align-items-center'>
                                                                        Total : {totalCnt} 건 &nbsp;
                                                                        {/* 
                                                                        <Dropdown className='search-drop'>
                                                                            <Dropdown.Toggle as="div"
                                                                                className="search-drop-btn">
                                                                                {row}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={()=>setRowData('10')}>10
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={()=>setRowData('20')}>20
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={()=>setRowData('30')}>30
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                        건씩 표시중
                                                                        */}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <table id="example4"className="table table-bordered">
                                                                <caption>강의 정보 세부기록 자료를 보여주는 테이블입니다.</caption>
                                                                <thead>
                                                                    <tr>
                                                                        {theadData.map((item, ind)=>(
                                                                            <th
                                                                                key={ind}
                                                                                onClick={() => {
                                                                                    handleSorting(item.sortingValue);
                                                                                }}
                                                                                role="button"
                                                                                tabIndex={0} 
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                                                        handleSorting(item.sortingValue);
                                                                                    }
                                                                                }}
                                                                                aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                                            >
                                                                                {item.heading}
                                                                                <span>
                                                                                    {item.sortingValue && (
                                                                                    <span>
                                                                                        {sort.column === item.sortingValue &&
                                                                                            (!sort.ascending ?
                                                                                                <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                                                :
                                                                                                <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                                            )
                                                                                        }
                                                                                        {sort.column !== item.sortingValue &&
                                                                                            <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                                </span>
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tableData.map((data, ind)=>(
                                                                        <tr key={ind}>
                                                                            <td>{data.name}</td>
                                                                            <td>{data.user_email}</td>
                                                                            {/* 
                                                                            <td>{data.lectureType}</td>
                                                                            <td>{data.memberType}</td>
                                                                            <td>{data.belong}</td>
                                                                            */}
                                                                            <td>{data.starttime}</td>
                                                                            <td>{data.endtime}</td>
                                                                            <td>{data.duration_toString}</td>
                                                                            {/* 
                                                                            <td>
                                                                                {data.status === "승인" ? (
                                                                                    <span className="badge badge-success light">
                                                                                        {data.status}
                                                                                    </span>
                                                                                ) : data.status === "대기" ? (
                                                                                    <span className="badge badge-light light ">
                                                                                        {data.status}
                                                                                    </span>
                                                                                ) : data.status === "진행" ? (
                                                                                    <span className="badge badge-primary light">
                                                                                        {data.status}
                                                                                    </span>
                                                                                ) :
                                                                                    data.status === "종료" ? (
                                                                                    <span className="badge badge-danger light">
                                                                                        {data.status}
                                                                                    </span>
                                                                                ) :  null}
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                {data.attendance === "출석" ? (
                                                                                    <span className="badge badge-primary light">
                                                                                        {data.attendance}
                                                                                    </span>
                                                                                ) :
                                                                                    data.attendance === "결석" ? (
                                                                                        <span className="badge badge-danger light">
                                                                                        {data.attendance}
                                                                                        </span>
                                                                                ) :  null}
                                                                            </td>
                                                                            */}
                                                                            <td>
                                                                                <span className="badge badge-primary light">
                                                                                    출석
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {/* 
                                                            <div
                                                                className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                                                <div className='dataTables_paginate paging_simple_numbers'
                                                                    id='example5_paginate'>
                                                                   <Link
                                                                        className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                                        to='#'
                                                                        onClick={prevPage}
                                                                        aria-label="이전 페이지로 이동">
                                                                        이전
                                                                    </Link>
                                                                    <span>
                                                                        {renderPaginationButtons()}
                                                                    </span>
                                                                    <Link
                                                                        className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                                        to='#'
                                                                        onClick={nextPage}
                                                                        aria-label="다음 페이지로 이동"
                                                                    >
                                                                        다음
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* 공유 모달창 */}
            <Modal className="fade reasonmodal" show={modalCentered} onHide={setModalCentered} centered>
                <Modal.Header>
                    <Modal.Title>기록 공유</Modal.Title>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="btn-close"
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='content'>
                        <p><b>공유 링크:</b> <br />
                            <b className='linktxt'>{copyUrl}</b>
                        </p>
                        <p><b>암호:</b> {copyPassword}</p>
                        <Button className="btn btn-primary light" onClick={() => copyToClipboard()}>복사</Button>                                      
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LectureData;