import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Col, Modal ,Button } from 'react-bootstrap';
import { DatePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var dataList = [];

const theadData = [
    {heading: '수강회차', sortingValue:"round"},
    {heading: '수강시작일', sortingValue:"startdate"},
    {heading: '수강종료일', sortingValue:"enddate"},
    {heading: '비고', sortingValue:""},
];

const BelongRegi = () => {  

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '소속 수정' : '소속 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        lectureid: '',
        status: '',
        note: ''
    });
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])

    const [menuName, setMenuName] = useState({
        activeMenu: '소속등록하기',
        pageContent: '소속 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    // 강의유형 ASC 정렬
    const changeLectureType = () => {
        const sortedData = [...lecturetypes].sort((a, b) => {
            if (a.lectureid < b.lectureid) return -1;
            if (a.lectureid > b.lectureid) return 1;
            return 0;
        });
        setLecturetypeData(sortedData);
    }

    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
                changeLectureType();
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        if (id != 0) {
            // 여기서는 데이터를 가져오는 비동기 작업을 수행하고 formData를 채워야 함
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '소속수정하기',
                pageContent: '소속 수정'
            }))
            getAffiliation();
        }
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'availableno' || name === 'roundno' || name === 'lectureid') {  
           setFormData(prevFormData => ({
              ...prevFormData,
              [name]: Number(value)
           }));
        } else {
           setFormData(prevFormData => ({
              ...prevFormData,
              [name]: value
           }));
        }
    };

    // 날짜 변경 시
    const handleStartDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            startdate: e
        }));
    };

    // 날짜 변경 시
    const handleEndDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enddate: e
        }));
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.name === ""){
            alert("소속명을 입력해주세요.");
            return false;
        }
        if(formData.lecturetype === ""){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        
        if(mode === "insert"){
            insertAffiliation();
        }else{
            updateAffiliation();
        }
    };

    // 정보 가져오기
    const getAffiliation = async () => {
            
        try {
            const response = await axios.get(apiUrl+'/affiliation/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            console.log(res.data);
            if(res.success){
                var resData = res.data;

                setFormData({
                    ...formData,
                    name: resData.name,
                    lectureid: resData.lectureid,
                    status: resData.status,
                    note: resData.note
                });

                // setTotalCnt(resData.affiliationround_list.length);
                // setTableData(resData.affiliationround_list);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 소속 등록
    const insertAffiliation = async () => {
        checkFormData();

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/affiliation/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("소속 등록하였습니다.");
                navigate('/AllBelong');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 소속 수정
    const updateAffiliation = async () => {
        var params = {...formData};
        params.affiliationid = id;
        //console.log(params)

        try {
            const response = await axios.put(apiUrl+'/affiliation/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("소속 수정하였습니다.");
                navigate('/AllBelong');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 소속 삭제
    const deleteAffiliation = async () => {
        var result = window.confirm("소속을 삭제하시겠습니까?");
        if(result){
            var affiliationid = id;
            try {
                const response = await axios.delete(apiUrl+'/affiliation/delete/' + affiliationid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("소속 삭제하였습니다.");
                    navigate('/AllBelong');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.name == ""){
            alert("소속명을 입력해주세요.");
            return false;
        }
        if(formData.lectureid == ""){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.roundno == ""){
            alert("수강회사를 입력해주세요.");
            return false;
        }
        if(formData.availableno == ""){
            alert("수강가능횟수를 입력해주세요.");
            return false;
        }
        if(formData.status == ""){
            alert("상태를 선택해주세요.");
            return false;
        }
    }

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllBelong');
    }

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
    
    }


     // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
        }
    }, [sort]);

    return (
        <>
            <PageTitle  motherMenu={"회원관리"} grandMenu={"소속관리"} grandMenuLink="/AllBelong"  activeMenu={menuName.activeMenu}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_Name">소속명</label>
                                            <input id="Enter_Name" placeholder="소속명 입력" type="text" name="name"
                                                value={formData.name} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>                                
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType">강의유형</label>
                                            <div className='d-flex gap-2 flex-wrap'>
                                                {lecturetypeData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`lectureid-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="lectureid" id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid===item.lectureid}
                                                            onChange={handleChange} />
                                                        {item.lecturetype}</label>
                                                </div>
                                                ))}

                                                <Link to="/lectureTypeRegi/0" className="btn btn-primary light">+
                                                강의유형 추가</Link>
                                            </div>
                                        </div>
                                    </div>

                                     {/* <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">수강 시작일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="수강시작일 선택"
                                                    value={formData.startdate} 
                                                    onChange={handleStartDateChange}
                                                    name="startdate"
                                                    className="picker-suit"
                                                    aria-label="수강시작일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">수강 종료일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="수강종료일 선택"
                                                    value={formData.enddate} 
                                                    onChange={handleEndDateChange}
                                                    name="enddate"
                                                    className="picker-suit"
                                                    aria-label="수강종료일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor='classRound' className="form-label d-block">수강회차</label>
                                            <div className=' form-check-inline'>
                                                <input id="classRound" placeholder="숫자입력" type="number" name="roundno"
                                                    value={formData.roundno} onChange={handleChange}
                                                    className="form-control " required aria-label="수강회차 숫자만 입력"/>
                                            </div>
                                            <div className=" form-check-inline">
                                                회차
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="classCount">수강가능횟수</label>
                                            <input id="classCount" placeholder="숫자입력" type="number" name="availableno"
                                                value={formData.availableno} onChange={handleChange}
                                                className="form-control" required aria-label="수강가능횟수 숫자만 입력"/>
                                        </div>
                                    </div>  */}
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="status">상태</label>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id='status-normal'
                                                    value='normal'
                                                    checked={formData.status==='normal'}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor='status-normal'>정상</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id='status-secession'
                                                    value='secession'
                                                    checked={formData.status==='secession'}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor='status-secession'>탈퇴</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Address">비고</label>
                                            <textarea id="Address" placeholder="비고 입력" name="note"
                                                value={formData.note} onChange={handleChange}
                                                className="form-control" rows="5"  
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">수강기간 관리</h5>
                        </div>
                        <div className="card-body">
                             <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>{data.roundno}</td>
                                                    <td>{data.startdate}</td>
                                                    <td>{data.enddate}</td>
                                                    <td>{data.note}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default BelongRegi;