import React, {useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
import { set } from 'rsuite/esm/utils/dateUtils';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: 'No.', sortingValue :"datetxt"},
    {heading: '강의날짜', sortingValue :"startdate"},
    {heading: '요일', sortingValue :"weektxt"},
    {heading: '강사', sortingValue :"tutorname"},
    {heading: '시작시간', sortingValue :"starttxt"},
    {heading: '종료시간', sortingValue :"endtxt"},
    {heading: '교재', sortingValue :""},
    {heading: '차시', sortingValue :"round"},
    {heading: '출석여부', sortingValue :"attendance"},
    {heading: '사유', sortingValue :""},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const ClassDetail = () => {

    useTitle("수강 관리 내역");

    const { id, roundno } = useParams(); // URL 매개변수에서 ID 가져오기

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);

    const [formData, setFormData] = useState({
        affiliationname: '',
        attendance_rate: 0,
    });

    // 수강내역 리스트 가져오기
    const getMyClassSchedule = async () => {
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }else{
            sortStr = "startdate,asc";
        }

        try {
            const response = await axios.get(apiUrl+'/tutoring/MyClassSchedule?tuteeid='+id+'&round='+roundno+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr, 
                {
                    headers: {
                        token: accessToken
                    }
                }
            );
            var res = response.data;
            // console.log(res);
            var dataArr = [];
            if(res.success){

                setFormData({
                    affiliationname: res.data.affiliationname,
                    attendance_rate: res.data.attendance_rate,
                });

                var resData = res.data.schdules;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var startdateStr = info.startdate.substring(0, 10);
                        info.startdateToString = startdateStr;
                        var enddateStr = info.enddate.substring(0, 10);
                        info.enddateToString = enddateStr;
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 엑셀 다운로드
    const downloadExcel = async () => {
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }else{
            sortStr = "startdate,asc";
        }
        
        try {
            const response = await axios.get(apiUrl+'/tutoring/excel?tuteeid='+id+'&round='+roundno+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            
            // Blob 데이터를 그대로 저장
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '수강내역.xlsx');

        } catch (error) {
           console.error('Error:', error);
        }
    }

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getMyClassSchedule();
        }else{
            activePage.current = 1;
            getMyClassSchedule();
        }
    }, [row]);
    
    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getMyClassSchedule();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getMyClassSchedule();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getMyClassSchedule();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getMyClassSchedule();
        }
    }, [sort]);
    
    return (
        <>
            <PageTitle motherMenu={"회원관리"} grandMenu={"수강생관리"} grandMenuLink="/AllStudent" activeMenu="수강 내역" />
           
            <Row>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                소속: {formData.affiliationname} &nbsp;&nbsp;&nbsp;&nbsp;
                                출석률: {formData.attendance_rate}%
                            </h5>
                            <button type='button' onClick={downloadExcel} className="btn btn-primary btn-sm">EXCEL 다운로드</button>
              
                            
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td><strong>{data.num}</strong></td>
                                                    <td><strong>{data.datetxt}</strong></td>
                                                    <td><strong>{data.weektxt}</strong></td>
                                                    <td><strong>{data.tutorname}</strong></td>
                                                    <td><strong>{data.starttxt}</strong></td>
                                                    <td><strong>{data.endtxt}</strong></td>
                                                    <td><strong>{data.originfile}</strong></td>
                                                    <td><strong>{data.round}</strong></td>
                                                    <td>
                                                        {data.attendance === "출석" ? (
                                                            <span className="badge badge-primary light">
                                                                {data.attendance}
                                                            </span>
                                                        )  : data.attendance === "결석" ? (
                                                            <span className="badge badge-light light">
                                                                {data.attendance}
                                                            </span>
                                                        ) :  null}
                                                    </td>
                                                    <td><strong>{data.reason}</strong></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default ClassDetail;