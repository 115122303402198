import React, {useState, useRef, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DatePicker } from 'rsuite';
import { Dropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: '수강회차', sortingValue :"roundno"},
    {heading: '수강 시작일', sortingValue :"startdate"},
    {heading: '수강 종료일', sortingValue :"enddate"},
    {heading: '수강가능횟수', sortingValue :"availableno"},
    {heading: '비고', sortingValue :""},
    {heading: '수강내역', sortingValue :""},
];

const Class = () => {
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기

    useEffect(() => {
        document.title = '수강 등록'  // 페이지 제목 설정
    }, []);

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const navigate = useNavigate();

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);


    const [formData, setFormData] = useState({
        tuteeid: id,
        roundno: 0,
        startdate: new Date(),
        enddate: new Date(),
        availableno: 0,
        note: '',
    });

    // 전체 선택, 개별선택
    const [selectAll, setSelectAll] = useState(false);
    const [isChecked, setIsChecked] = useState(new Array(dataList.length).fill(false));
    const [checkedArr, setCheckedArr] = useState([]);

    // 전체 선택 체크박스를 클릭
    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        setIsChecked(new Array(tableData.length).fill(checked));
        if(checked){
            var checkedIndex = [];
            for(var i = 0; i < tableData.length; i++){
                checkedIndex.push(i);
            }
            setCheckedArr(checkedIndex);
        }else{
            setCheckedArr([]);
        }
    };

    // 개별 선택 체크박스를 클릭
    const handleCheckboxChange = (index) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);
        
        var checkedIndex = [];
        for(var i = 0; i < newIsChecked.length; i++){
            if(newIsChecked[i]){
                checkedIndex.push(i);
            }
        }
        setCheckedArr(checkedIndex);
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getListData();
        }else{
            activePage.current = 1;
            getListData();
        }
    }, [row]);
    

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getListData();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getListData();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getListData();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getListData();
        }
    }, [sort]);

    useEffect(() => {

        // 수강 등록 관리 리스트
        const getListData = async () => {
            try {
                const [roundResponse] = await Promise.all([
                    axios.get(apiUrl + '/tutee/round/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);

                var res = roundResponse.data;
                if(res.success){
                    const dataList = res.data;
                    setTableData(dataList);
                    setTotalCnt(dataList.length);
                }else{
                    alert(res.msg);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            getListData();
        }
        
    }, [id]);

    // 수강 등록 관리 리스트
    const getListData = async () => {
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }else{
            sortStr = "roundno,asc";
        }

        try {
            const [roundResponse] = await Promise.all([
                axios.get(apiUrl + '/tutee/round/' + id + '?sort='+ sortStr, {
                    headers: {
                        token: accessToken
                    }
                }),
            ]);

            var res = roundResponse.data;
            if(res.success){
                const dataList = res.data;
                setTableData(dataList);
                setTotalCnt(dataList.length);
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'roundno' || name === 'availableno') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 날짜 변경시
    const handleStartDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            startdate: e
        }));
    };

    // 날짜 변경시
    const handleEndDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enddate: e
        }));
    };

    // 엑셀 다운로드
    const downloadExcel = async () => {
        
        try {
            const response = await axios.get(apiUrl+'/tutee/excelround/'+id, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            
            // Blob 데이터를 그대로 저장
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '수강등록내역.xlsx');

        } catch (error) {
           console.error('Error:', error);
        }
    };
    
    // 삭제
    const handleDelete = async () => {
        var selectedids = "";
        if(selectAll){
            for(var i = 0; i < tableData.length; i++){
                if(i == 0){
                    selectedids = tableData[i].roundno;
                }else{
                    selectedids += "," + tableData[i].roundno;
                }
            }
        }else{
            if(checkedArr.length == 0){
                alert("삭제할 항목을 선택해주세요.");
                return false;
            }else{
                for(var i = 0; i < checkedArr.length; i++){
                    if(i == 0){
                        selectedids = tableData[checkedArr[i]].roundno;
                    }else{
                        selectedids += "," + tableData[checkedArr[i]].roundno;
                    }
                }
            }
        }

        var result = window.confirm("선택된 항목을 삭제하시겠습니까?");
        if(result){
            
            try {
                const response = await axios.delete(apiUrl+'/tutee/deleteround/' + id + '?roundno=' + selectedids, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("삭제하였습니다.");
                    activePage.current = 1;
                    setSelectAll(false);
                    setIsChecked(new Array(tableData.length).fill(false))
                    setCheckedArr([]);
                    getListData();
                }else{
                    alert(res.msg);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var params = {...formData};

        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/tutee/round', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("수강 등록하였습니다.");
                getListData();
                resetFormData();
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 초기화
    const resetFormData = () => {
        setFormData({
            tuteeid: id,
            roundno: 0,
            startdate: new Date(),
            enddate: new Date(),
            availableno: 0,
            note: '',
        });
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.startdate == "" || formData.startdate == null){
            alert("시작일을 입력해주세요.");
            return false;
        }
        if(formData.enddate == "" || formData.enddate == null){
            alert("종료일을 입력해주세요.");
            return false;
        }
        if(formData.roundno == "" || formData.roundno == 0 || formData.roundno == "0"){
            alert("수강회차를 입력해주세요.");
            return false;
        }
        if(formData.availableno == "" || formData.availableno == 0 || formData.availableno == "0"){
            alert("수강가능횟수를 입력해주세요.");
            return false;
        }
        return true;
    }

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllStudent');
    }

    return (
        <>
            <PageTitle  motherMenu={"회원관리"} grandMenu={"수강생관리"} grandMenuLink="/AllStudent" activeMenu="수강등록"/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">수강 등록</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="classStdate">수강 시작일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3 ">
                                                <DatePicker 
                                                    placeholder="시작일 선택" 
                                                    value={formData.startdate} 
                                                    onChange={handleStartDateChange}
                                                    name="startdate"
                                                    className="picker-suit" 
                                                    aria-label="시작일 선택" 
                                                    id="classStdate"/>
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="classEnddate">수강 종료일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3 ">
                                                <DatePicker 
                                                    placeholder="종료일 선택" 
                                                    value={formData.enddate} 
                                                    onChange={handleEndDateChange}
                                                    name="enddate"
                                                    className="picker-suit" 
                                                    aria-label="종료일 선택" 
                                                    id="classEnddate"/>
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor='classRound' className="form-label d-block">수강회차</label>
                                            <div className=' form-check-inline'>
                                                <input id="classRound"
                                                    placeholder="숫자입력" 
                                                    type="number" 
                                                    className="form-control" 
                                                    name="roundno"
                                                    value={formData.roundno} onChange={handleChange}
                                                />
                                            </div>
                                           <span>회차</span>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="classCount">수강가능횟수</label>
                                            <input id="classCount" 
                                                placeholder="숫자입력" 
                                                type="number" 
                                                className="form-control" 
                                                name="availableno"
                                                value={formData.availableno} onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="note">비고</label>
                                            <textarea id="note" 
                                                placeholder="비고 입력" 
                                                name="note"
                                                value={formData.note} onChange={handleChange}
                                                className="form-control" 
                                                rows="5"  
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">수강 등록 관리</h5>
                            <div className="d-flex  gap-2">
                                <button type='button' className="btn btn-primary btn-sm" onClick={downloadExcel}>EXCEL 다운로드</button>
                                <button type='button' className="btn btn-danger btn-sm" onClick={handleDelete}>삭제</button>
                            </div>
                        </div>
                        <div className="card-body">
                           <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                 <th>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="checkAll"
                                                        checked={selectAll} 
                                                        onChange={handleSelectAll} 
                                                        aria-label="모두 선택"
                                                    />
                                                </th>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={isChecked[ind]}
                                                            onChange={() => handleCheckboxChange(ind)} 
                                                            aria-label={`선택: ${data.name}`}
                                                        />
                                                    </td>
                                                    <td><strong>{data.roundno}</strong></td>
                                                    <td>{data.startdate}</td>
                                                    <td>{data.enddate}</td>
                                                    <td>{data.availableno}</td>
                                                    <td>{data.note}</td>
                                                    <td className='btns'>
                                                        <Link to={"/ClassDetail/"+id+"/"+data.roundno}
                                                            className="btn btn-primary me-1 light">관리</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Class;